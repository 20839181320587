<script setup>
definePageMeta({
  layout: 'minimal',
})

const backend = useBackend();
const route = useRoute();
const analytics = useAnalytics();

const {leasingDrawerOpened} = useLeasingParams();
const {onWindowEvent} = useWindowEvents();
const {onMouseUp} = useMouseEvents();

const refCarCompare = ref();
const refCarCompareHeaders = ref();
const refSearchParameters = ref();

const filters = ref({});
const presets = ref({});
const refCard = ref([]);
const equip = ref(new Map);
const visibleAll = ref(false);

const sortOrder = ref(1);
const filterState = ref("");


const {data: allCars, error} = await useAsyncData(() => new Promise(async (resolve, reject) => {
  try {
    const cars = await backend.getCars({
      brandName: route.params?.brand,
      modelName: route.params?.model,
    });
    cars.data = cars.data.filter(i => i.price);

    if (!cars.data.length) {
      reject(new Error('No cars with price found'));
      return;
    }


    resolve(cars.data);
  } catch (err) {
    reject(err);
  }
}), { default: () => [] });



if (error.value) {
  const badRoutes = useBadRoutes();
  badRoutes.handle();
}

const  applyCarFilters = (cars, filters, equip, excludeStateFilter = false)=> {
  return cars.filter(car => {
    let result = true;

    Object.keys(filters).forEach(filterKey => {
      const filter = filters[filterKey];
      if ((!excludeStateFilter || filterKey !== 'state') && filter.length) {
        switch (filterKey) {
          case "body":
            if (filter.indexOf(car.bodyType.toLowerCase()) === -1) result = false;
            break;
          case "fuel":
            if (filter.indexOf(car.fuelType.toLowerCase()) === -1) result = false;
            break;
          case "gearbox":
            if (filter.indexOf(car.gearboxType) === -1) result = false;
            break;
          case "powerMin":
            if (parseInt(car.horsePower) < filter[0]) result = false;
            break;
          case "powerMax":
            if (parseInt(car.horsePower) > filter[0]) result = false;
            break;
          case "seats":
            if (filter.indexOf(car.seats.toString()) === -1) result = false;
            break;
          case "drive":
            if (filter.indexOf(car.driveType) === -1) result = false;
            break;
          case "productionYear":
            if (filter.indexOf(car.productionYear.toString()) === -1) result = false;
            break;
          case "state":
            if (filter.indexOf(car.state) === -1) result = false;
            break;
        }
      }
    });

    if (result && equip.size) {
      equip.forEach((value, key) => {
        if (Object.values(car.equipment).indexOf(parseInt(key)) === -1) {
          result = false;
        }
      })
    }

    return result;
  });
}

const cars = computed(() => {
  let resultCars = applyCarFilters(allCars.value, filters.value, equip.value);

  resultCars = resultCars.sort((a, b) => (a.price - b.price) * sortOrder.value);

  resultCars = resultCars.sort((a, b) => {
    let xLabel = a.labels.find(i => i.code.toLowerCase() === 'portal_first') ? 1 : 0;
    let xCost = a.price;
    let yLabel = b.labels.find(i => i.code.toLowerCase() === 'portal_first') ? 1 : 0;
    let yCost = b.price;

    return xLabel && yLabel ? yCost - xCost : yLabel - xLabel;
  });

  return resultCars;
});

const carConditions = computed(() => {
  const filteredAllCars = applyCarFilters(allCars.value, filters.value, equip.value, true);

  const usedCarsCount = filteredAllCars.filter(c => c.state === 'Używany').length;
  const newCarsCount = filteredAllCars.filter(c => c.state === 'Nowy').length;

  return [
    { label: 'Wszystkie', count: filteredAllCars.length },
    { label: 'Nowe', count: newCarsCount },
    { label: 'Używane', count: usedCarsCount }
  ];
});

const brand = computed(() => {
  return Array.isArray(allCars.value) && allCars.value.length > 0 ? allCars.value[0]?.brandName || "" : "";
});

const model = computed(() => {
  return Array.isArray(allCars.value) && allCars.value.length > 0 ? allCars.value[0]?.modelName || "" : "";
});

useHead({
  title: `Leasing ${brand.value.toUpperCase()} ${model.value.toUpperCase()} od ręki!`,
  meta: [
    {name: 'description', content: `Leasing samochodu w Vehis.pl  ✔️ Rabaty dla przedsiębiorców ✔️ Decyzja w 15 min ✔️ Atrakcyjne warunki finansowania ✔️ Sprawdź!`},
    {name: 'robots', content: 'noindex, follow'}
  ],
  link: [
    {rel: 'canonical', href: 'https://vehis.pl' + route.path}
  ]
});

const filterCars = (filterData) => {
  flash();
  filters.value = filterData;
  filters.value.state = filterState.value;
}

const flash = () => {
  refCarCompare.value.$el.style.opacity = 0.5;
  setTimeout(() => refCarCompare.value.$el.style.opacity = 1, 100);
}

const {data: equipmentData} = await useAsyncData(async () => {
  const equip = await backend.getEquipmentDictionary();
  return equip.data.data;
})

const equipmentDict = computed(() => {
  const result = new Map;
  Object.keys(equipmentData.value).forEach(group => {
    Object.keys(equipmentData.value[group]).forEach(id => {
      const name = equipmentData.value[group][id];
      let exists = false;
      allCars.value.forEach(car => {
        if (!exists) {
          if (!!car.equipment.find(i => i == id)) {
            exists = true;
          }
        }
      });

      if (exists && id !== '9999') {
        result.set(id, name)
      }
    })
  })

  return new Map([...result.entries()].sort((a, b) => String(a[1]).localeCompare(b[1], 'pl')));
})

const clearFilters = () => {
  flash();
  filters.value = [];
  filterState.value = "";
  equip.value = new Map;
  selectedCondition.value = "Wszystkie";
  refSearchParameters.value.clearForm();
}

watch(
  () => equip.value,
  () => flash(),
  {deep: true}
)

const connectSlideControls = (type) => {
  if (type === 'main' && dragEventOwner === null) {
    dragEventOwner = 'main';
  }
  if (type !== dragEventOwner) return false;
  switch (type) {
    case 'main':
      refCarCompareHeaders.value.$el.scrollLeft = refCarCompare.value.$el.scrollLeft;
      break;
    case 'headers':
      refCarCompare.value.$el.scrollLeft = refCarCompareHeaders.value.$el.scrollLeft;
      break;
  }
  return true;
}

let dragEventOwner = null;
const setDragOwner = (type) => {
  dragEventOwner = type;
}

const prepareFilters = () => {
  const hash = route.hash ? route.hash.substring(1) : null;
  if (hash) presets.value.body = [hash.toLowerCase()];
}

onMounted(() => {
  prepareFilters();
  const { breakpoint } = useBreakpoints();
  if(!breakpoint.value || breakpoint.value === 'sm'){
    leasingDrawerOpened.value = false;
  } else {
    openFinancingDrawer();
  }
})

const showHeaders = ref(false);
onWindowEvent('scroll', (e) => {
  if (!refCarCompare.value?.$el) return;
  const box = refCarCompare.value.$el.getBoundingClientRect();
  showHeaders.value = box.y <= -390;
})

const openFinancingDrawer = () => {
  leasingDrawerOpened.value = true;
}

onMouseUp(() => {
  dragEventOwner = null;
})

watch(() => cars.value.length, (count) => {
  if (count === 0) {
    analytics.analyticsLayer({'event': 'filter_no_result'});
  }
})



const selectedCondition = ref('Wszystkie');
const selectCondition = (condition) => {
  switch(condition) {
    case "Używane":
      filterState.value = "Używany"
      break;
    case "Nowe":
      filterState.value = "Nowy"
      break;
    default:
      filterState.value = "";
      break;
  }
  filters.value.state = filterState.value;
  selectedCondition.value = condition;
};
</script>

<template>
  <Container no-paddings class="relative">
    <div class="flex justify-between items-center w-full pt-8 flex-wrap lg:flex-nowrap">
      <MainContentHeader :counter="cars.length" class="pb-4 md:w-full">Wybierz samochód z&nbsp;dostępnych {{ brand }} {{ model }}</MainContentHeader>
      <div class="flex gap-4 items-center sm:ml-auto lg:ml-0 justify-between sm:justify-normal w-full sm:w-auto">
        <ButtonPlain class="w-fit text-black whitespace-nowrap !font-extralight" @click="clearFilters" small>
          <template v-slot:default>Wyczyść wszystko</template>
          <template v-slot:rightSlot>
            <IconTrashCan class="!w-4 !h-4" />
          </template>
        </ButtonPlain>

        <ButtonToggle class="text-sm font-extralight whitespace-nowrap" @toggle="(toggled) => sortOrder = toggled ? -1 : 1">
          <template v-slot:default>Sortuj: po cenie ↑</template>
          <template v-slot:toggled>Sortuj: po cenie ↓</template>
        </ButtonToggle>
      </div>
    </div>

    <div class="pb-2">
      <SearchParametersMinimal ref="refSearchParameters"
                               :cars="allCars"
                               :available-equipment="equipmentDict"
                               v-model:preset="presets"
                               @change="filterCars"
      />
    </div>
    <div class="py-4">
      <CarEquipmentCloud :tags="equipmentDict" v-model:selected="equip" @click="(name) => analytics.analyticsLayer({event: 'filter_wyposazenie', filter_type: name})"></CarEquipmentCloud>
    </div>
  </Container>
  <Container no-paddings class="relative">
    <LayoutColumns>
      <LayoutMainColumn noflex>
        <div class="flex gap-2 text-sm font-extralight select-none sticky top-[56px] md:top-[72px] group-[.body-expanded]:lg:top-[230px] group-[.body-expanded]:sm:top-[230px] group-[.body-expanded]:xl:top-[146px] -ml-4 -mr-4 lg:mr-0 pl-4 py-2 -mb-3 z-30 bg-gradient-to-b from-80% from-white to-transparent pt-2 h-10 items-center">
          <p
            v-for="condition in carConditions"
            :key="condition.label"
            :class="['cursor-pointer', 'flex', selectedCondition === condition.label ? 'font-normal text-azure' : 'font-extralight']"
            @click="selectCondition(condition.label)"
          >
            {{ condition.label }} ({{ condition.count }})<span v-if="condition.label !== 'Używane'" class="text-black font-extralight block pl-2">|</span>
          </p>
        </div>
        <ScrollBarForSlideControl :scrolledElement="refCarCompare"
                                  class="z-30 sticky
                                  top-[84px] sm:top-[84px] md:top-[100px]
                                   group-[.body-expanded]:md:top-[256px]
                                  group-[.body-expanded]:lg-top-[174px]
                                  group-[.body-expanded]:xl:top-[174px]
                                  -ml-4 -mr-4 lg:mr-0 pl-4 py-2 -mb-5 bg-gradient-to-b from-80% from-white to-transparent" />
        <div class="sticky transition-all
                  top-[116px] sm:top-[124px] md:top-32
                          group-[.body-expanded]:sm:top-[280px]
                          group-[.body-expanded]:xl:top-[200px]
                          group-[.body-expanded]:lg:top-72
        z-20 h-1 opacity-0  pointer-events-none" :class="{'opacity-100 !pointer-events-auto': showHeaders}">
          <SlideControl ref="refCarCompareHeaders"
                        class="bg-gradient-to-b from-80% from-white to-transparent !gap-4"
                        :size=280 :gap=16
                        @scroll="connectSlideControls('headers')"
                        @mousedown="setDragOwner('headers')"
                        @touchstart="setDragOwner('headers')">
            <SlideItem v-for="(car, idx) in cars" :index="idx" :key="car.group_id" thin>
              <div class="bg-white w-full font-normal text-sm -ml-2 px-2 select-none"
                   :class="{'border-l border-l-gray-400' : idx > 0}">
                <div class="overflow-x-hidden overflow-ellipsis whitespace-nowrap border-b border-b-gray-400 pb-2 mb-2">
                  {{ car?.versionName }}
                </div>
                <div class="flex">
                  <div class="w-[212px] h-[80px] flex justify-center items-start overflow-hidden" @dragstart.prevent="">
                    <div>
                      <NuxtImg v-if="car?.image || car?.images[0]" :src="car?.image || car?.images[0]" class="h-15 w-full object-cover z-10 mb-1" :alt="car?.brandName ? (car?.brandName + ' ' + car?.versionName) : ''" loading="lazy" />
                      <div class="flex">
                        <p class="flex text-xs font-extralight items-center mr-4">
                          <IconCarProductedDate class="w-4 h-4"/>
                          <span class="ml-1">{{ car?.productionYear }}</span>
                        </p>
                        <p class="flex text-xs font-extralight items-center">
                          <IconCarMileage class="w-4 h-4"/>
                          <span class="ml-1">{{ priceFormat(car?.mileage) }} km</span>
                        </p>
                    </div>
                </div>
                  </div>
                  <div class="shrink-0 flex flex-col items-end">
                    <div class="text-xs font-extralight">Twoja rata
                      <EffectNetGrossIndicator />
                    </div>
                    <div class="text-azure">
                      <EffectOdomoeter class="text-3xl font-normal" :value="car.installment" />
                      <span class="text-sm font-extralight"> zł</span>
                    </div>
                    <div class="w-full">
                      <ButtonRounded color="gray" class="w-full" :href="{name: 'offer', params: {id: car?.group_id}}" small>Oferta</ButtonRounded>
                    </div>
                  </div>

                </div>
              </div>
            </SlideItem>
            <SlideItem v-if="!cars.length">

            </SlideItem>
          </SlideControl>
        </div>

        <SlideControl ref="refCarCompare" class="!gap-4 transition-opacity" :size=280 :gap=16
                      @scroll="connectSlideControls('main')"
                      @mousedown="setDragOwner('main')"
                      @touchstart="setDragOwner('main')">
          <SlideItem v-for="(car, idx) in cars" :index="idx" thin>

            <CarCard :ref="(el) => refCard.push(el)"
                     :car="car"
                     choose-view
                     no-adjust
                     thin />
            <CarEquipment :car="car"
                          :dictionary="equipmentDict"
                          :control="idx === 0"
            />
          </SlideItem>
          <SlideItem v-if="!cars.length" thin>
            <CarCardEmpty :car="allCars[0]" @clear="clearFilters" thin />
          </SlideItem>
        </SlideControl>

        <div class="pb-10">
          <ModelShowCars :label="`Chcesz dowiedzieć się więcej o ${brand} ${model} ?`"
                         button-text="Poznaj model"
                         :href="{name: 'model', params: {brand: brand.toLowerDashed(), model: model.toLowerDashed()}, hash: route.hash }"
          />
        </div>
      </LayoutMainColumn>
      <LayoutSideColumn class="!pl-4 !z-30">
        <FormContact class="lg:top-20 group-[.body-expanded]:xl:top-40 group-[.body-expanded]:lg:top-60 !z-30" :car="cars[0] || allCars[0]" docking />
      </LayoutSideColumn>
    </LayoutColumns>
    <CarFavoritesBar />
  </Container>
</template>
